@tailwind base;
@tailwind components;
@tailwind utilities;

html:root {
  --primary-color: #232B35;
  --secondary-color: #C63C51;
  --tertiary-color: #9FA5AD;
}

@media (prefers-color-scheme: dark) {
  html:root {
    --primary-color: #E2E5ED;
    --secondary-color: #C63C51;
    --tertiary-color: #65687A;
  }
}

@font-face {
  font-family: 'Sansation';
  src: local('Sansation'), url('./../fonts/Sansation_Regular.ttf')
}

html, body {
  background-color: #F6F8FB;
}

body {
  margin: 0;
  font-family: 'Sansation', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (prefers-color-scheme: dark) {
  html, body {
    background-color: #0F1013; /* Couleur adaptée pour le mode sombre */
  }
}